<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <!-- Header -->
    <main-header />
    <section v-if="currentArtwork">
      <div class="container" id="home-container">
        <!-- Breadcrumb -->
        <div class="_flex _center">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb  bg-transparent tiny">
              <li class="breadcrumb-item"><a href="#">Accueil</a></li>
              <li class="breadcrumb-item"><a href="#">Oeuvres</a></li>
              <li class="breadcrumb-item active" aria-current="page" v-if="currentArtwork">
                {{ currentArtwork.name }}
              </li>
            </ol>
          </nav>
        </div>
        <!-- Artwork -->
        <div class="py-5 px-10-md px-20-lg" id="artworkView">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-7 col-xl-8 px-5-md px-8-lg">
              <!-- View -->
              <slideshow :images="images" v-if="currentArtwork" />
              <!-- Descriptiom -->
              <div class="mt-10">
                <h3 class="bold">Description</h3>
                <div class="title-separator"></div>
                <!--  -->
                <ul class="list-unstyled">
                  <li class="my-list-element _flex _wrap _between row">
                    <div class="col-12 col-md-4 medium ">Authentication</div>
                    <div class="col-12 col-md-8 text-md-right">
                      <div class="medium ">
                        {{ currentArtwork.is_unique === "1" ? "Oui" : "Non" }}
                      </div>
                    </div>
                  </li>
                  <li class="my-list-element _flex _wrap _between row">
                    <div class="col-12 col-md-4 medium ">Signature</div>
                    <div class="col-12 col-md-8 text-md-right">
                      <div class="text-muted ">
                        {{ currentArtwork.type_signature || "" }}
                      </div>
                    </div>
                  </li>
                  <li class="my-list-element _flex _wrap _between row">
                    <div class="col-12 col-md-4 medium ">Tirage</div>
                    <div class="col-12 col-md-8 text-md-right">
                      <div class="text-muted ">
                        {{ currentArtwork.is_limited_numbered === "1" ? "Oui" : "Non" }}
                      </div>
                    </div>
                  </li>
                  <li class="my-list-element _flex _wrap _between row">
                    <div class="col-12 col-md-4 medium ">
                      Dimensions <span class="text-muted ">cm</span> | inch
                    </div>
                    <div class="col-12 col-md-8 text-md-right">
                      <div class="medium ">
                        {{ currentArtwork.dim_height || "" }} x {{ currentArtwork.dim_width || "" }} x
                        {{ currentArtwork.dim_thickness || "" }} {{ currentArtwork.dim_unit || "" }}
                      </div>
                    </div>
                  </li>
                  <li class="my-list-element _flex _wrap _between row">
                    <div class="col-12 col-md-4 medium ">Encadrement</div>
                    <div class="col-12 col-md-8 text-md-right">
                      <div class="text-muted ">
                        {{ currentArtwork.is_framed_artwork === "1" ? "Oui" : "Non" }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- About -->
              <div class="mt-10">
                <div class="border-top pt-8 pb-5">
                  <h6>À propos de l'oeuvre</h6>
                </div>
                <p class="text-muted">{{ currentArtwork.description }}</p>
              </div>
              <!-- Purchase info -->
              <div class="mt-10">
                <ul class="list-unstyled _flex medium border-top pt-8 pb-5">
                  <li class="artists_follow_link_active mr-8">Livraison</li>
                  <li class="text-muted mr-8">Paiement</li>
                  <li class="text-muted ">Garantie</li>
                </ul>
                <div class="py-4">
                  <p>
                    Mirior d'art livre les oeuvres dans le monde entier via des
                    transporteurs spécialisés.
                  </p>
                  <p>
                    L'oeuvre peut être livrée à l'adresse de votre choix sous 1
                    à 2 semaines après validation de votre commande. L'oeuvre
                    est assurée pendant le transport, il n'y a donc aucun risque
                    pour vous.
                  </p>
                  <br />
                  <a href="" class="underline medium">En savoir plus sur la livraison</a>
                </div>
              </div>
              <!-- Share on social -->
              <div class="mt-10">
                <div class="border-top pt-8 pb-5">
                  <div class="medium mb-5">Partagez cette oeuvre</div>
                  <ul class="list-unstyled">
                    <li class="social">
                      <a href="" title="Facebook"><i class="icon-facebook"></i></a>
                    </li>
                    <li class="social">
                      <a href="" title="Twitter"><i class="icon-twitter"></i></a>
                    </li>
                    <li class="social">
                      <a href="" title="Linkedin"><i class="fab fa-linkedin"></i></a>
                    </li>
                    <li class="social">
                      <a href="" title="Email"><i class="icon-mail"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- contact -->
              <div class="title-separator"></div>
              <div class="mt-10">
                <h4 class="bold">Contacter nos experts</h4>
                <p class="text-muted">
                  Une question sur l'oeuvre? L'artiste? La livraison de
                  l'oeuvre?
                </p>
                <div class="tiny mb-3">* Champs obligatoires</div>
                <div class="form-group login-group mt-8 mb-2">
                  <label>Adresse email</label>
                  <input type="text" class="form-control rounded-0" />
                </div>
                <div class="form-group login-group mt-2 mb-2" style="height:120px!important;">
                  <label>Message</label>
                  <textarea cols="4" rows="4" class="form-control rounded-0"></textarea>
                </div>
                <button class="btn btn-follow btn-block black mb-4 mt-2 py-0">
                  Envoyez le message
                </button>
              </div>
            </div>
            <!-- Right side -->
            <div class="col-12 col-md-6 col-lg-5 col-xl-4" id="viewRightImage">
              <div class="artwork_fixed" v-if="currentArtwork">
                <img :src="`${currentArtwork.data_pictures ? getMainPictureArtwork(currentArtwork.data_pictures) : getEmptyArtworkPicture}`" alt="" class="artwork_fixed_img"/>
                <div class="d-flex flex-row _between artwork_fixed_body">
                  <div>
                    <!-- <div class="caption text-danger italic">
                      <i class="fal fa-clock"></i>&nbsp;4 jours restants
                    </div> -->
                    <div class="medium subheading">
                      {{ currentArtwork.name }}
                    </div>
                    <div class="italic text-uppercase">
                      {{ artist_full_name }}
                    </div>
                  </div>
                  <div class="text-right">
                    <div class="text-danger subheading bold">
                      {{ currentArtwork.price }} {{ currentArtwork.currency_id }}
                    </div>
                    <!-- <del class="font-size-13">929 $</del> -->
                  </div>
                </div>
              </div>
              <div class="my-1">
                {{ currentArtwork.total_number_copies }} examplaire{{
                  currentArtwork.total_number_copies > 1 ? "s" : ""
                }}
                disponible{{ currentArtwork.number_available_copies > 1 ? "s" : "" }}
              </div>
              <p class="caption text-muted mt-4">
                Livraison: {{ currentArtwork.delay_delivery }}
              </p>
              <br />
              <div class="form-inline d-flex justify-content-between align-items-center mb-3">
                <label class="is-13">Quantité:</label>
                <div class="quantity--update quantity--min">
                  <div class="qty">{{ qty }}</div>
                  <div class="qty-controls">
                    <div @click="qty += 1">
                      <i class="fal fa-plus"></i>
                    </div>
                    <div @click="qty -= 1">
                      <i class="fal fa-minus"></i>
                    </div>
                  </div>
                </div>
                <!-- <select class="form-control border-0" v-model.number="qty">
                  <option :value="n" v-for="n in 20" :key="n">{{ n }}</option>
                </select> -->
              </div>
              <button :disabled="addToCartLoading" class="btn black btn-block" @click="addToCart">
                <i class="far fa-spinner fa-pulse title" v-if="addToCartLoading"></i>
                Ajouter au panier
              </button>
              <button @click="$router.push('/panier')" class="btn btn-action black-outline text-uppercase btn-block body-2">
                Voir dans le panier
              </button>
              <!-- Add to wishlist -->
              <div class="_flex py-3 my-4 border-top border-bottom ">
                <i class="fal fa-heart "></i>
                <span class="ml-3 medium ">Ajouter à ma wishlist</span>
              </div>
              <!-- <p class="medium text-danger mt-0 mb-2 ">1 exemplaire restant</p>
              <div class="_flex medium ">
                <i class="mr-1 icon-eye-open "></i> 8 personnes visitent cette
                page
              </div> -->
            </div>
            <!-- ** View Right -->
            <div class="col-12 col-md-6 col-lg-5 col-xl-4" id="viewRightContent">
              <!--  -->
              <div>
                <img :src="`${currentArtwork.data_pictures ? getMainPictureArtwork(currentArtwork.data_pictures) : getEmptyArtworkPicture}`" alt="" class="img-thumbnail artwork_fixed_img"/>
                <h1 class="font-weight-bold mt-4 mb-4" style="line-height:1">
                  {{ currentArtwork.name || "" }}
                </h1>
                <p class="mb-2 title text-capitalize">
                  {{ currentArtwork.data_user.last_name || "" }} {{ currentArtwork.data_user.middle_name || "" }}
                  {{ currentArtwork.data_user.first_name || "" }}
                </p>
                <p class="mb-1 text-capitalize">
                  {{ currentArtwork.data_category.name }}
                </p>
                <p class="mb-1" v-if="currentArtwork">
                  {{ currentArtwork.dim_height  }} x {{ currentArtwork.dim_width }}
                </p>
                <p class="mb-1 ">
                  <span class="text-danger subheading medium ">{{ currentArtwork.price }} {{ currentArtwork.currency_id }}</span>
                  <!-- &nbsp;&nbsp;<del class="body-1 text-muted ">929 $</del
                  > -->
                  <!-- &nbsp;&nbsp;|&nbsp;&nbsp;<span class="text-danger "
                    ><i class="fal fa-clock "></i>
                    <em class="caption ">5 jours restants</em></span
                  > -->
                </p>
                <p class="caption text-muted ">
                  Livraison: {{ currentArtwork.delay_delivery }}
                </p>
                <br />
                <div class="form-inline d-flex justify-content-between align-items-center mb-3">
                  <label class="is-13">Quantité:</label>
                  <div class="quantity--update quantity--min">
                    <div class="qty">{{ qty }}</div>
                    <div class="qty-controls">
                      <div @click="qty += 1">
                        <i class="fal fa-plus"></i>
                      </div>
                      <div @click="qty -= 1">
                        <i class="fal fa-minus"></i>
                      </div>
                    </div>
                  </div>
                  <!-- <select class="form-control" v-model.number="qty">
                    <option :value="n" v-for="n in 20" :key="n">{{ n }}</option>
                  </select> -->
                </div>
                <button :disabled="addToCartLoading" class="btn black btn-block" @click="addToCart">
                  <i class="far fa-spinner fa-pulse title" v-if="addToCartLoading"></i>Ajouter au panier
                </button>
                <button @click="$router.push('/panier')" class="btn btn-action black-outline text-uppercase btn-block body-2">
                  Voir dans le panier
                </button>
                <!-- Add to wishlist -->
                <div class="_flex py-3 my-4 border-top border-bottom ">
                  <i class="fal fa-heart "></i>
                  <span class="ml-3 medium ">Ajouter à ma wishlist</span>
                </div>

                <div class="medium mb-5">Partagez cette oeuvre</div>
                <ul class="list-unstyled">
                  <li class="social">
                    <a href="" title="Facebook"><i class="icon-facebook"></i></a>
                  </li>
                  <li class="social">
                    <a href="" title="Twitter"><i class="icon-twitter"></i></a>
                  </li>
                  <li class="social">
                    <a href="" title="Linkedin"><i class="fab fa-linkedin"></i></a>
                  </li>
                  <li class="social">
                    <a href="" title="Email"><i class="icon-mail"></i></a>
                  </li>
                </ul>

                <!-- <p class="medium text-danger mt-0 mb-2 ">
                  1 exemplaire restant
                </p>
                <div class="_flex medium ">
                  <i class="mr-1 icon-eye-open "></i> 8 personnes visitent cette
                  page
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="container my-10 px-10-md px-20-lg">
        <div class="px-5-md px-8-lg">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3 d-flex flex-column align-items-center text-center">
              <img :src="currentArtwork.data_user.data_picture.src || getEmptyUserPicture" alt="" class="artwork_artist_img"/>
              <div class="_flex _center">
                <a href="" class="_flex no-hover">
                  <i class="icon-follow no-hover mr-2"></i>
                  <span class="animated-underline">Suivre</span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-8 col-lg-9">
              <h3 class="bold text-uppercase">{{ artist_full_name }}</h3>
              <div class="medium mb-4 _flex subheading">
                <i class="icon-here mr-2"></i>
                {{ currentArtwork.data_user.country }}
                <span class="mx-2" style="color:#999;font-weight:300;">|</span>
                Né(e) en: {{ currentArtwork.data_user.birthday }}
              </div>
              <div v-html="currentArtwork.data_user.apropos"></div>
            </div>
          </div>
          <!--  -->
          <div v-if="artist_artworks && artist_artworks.length > 0">
            <div class="_flex _between flex-wrap mb-8 mt-10 w-100">
              <div>
                <p class="headline bold m-0">
                  Oeuvres de <span class="text-capitalize">{{ artist_full_name }}</span>
                </p>
              </div>
              <div class="body-2 medium">
                <a href="" class="animated-underline">Accéder à toutes les oeuvres de l'artiste</a>
              </div>
            </div>
            <div class="card-columns mb-8">
              <artwork-card :artwork="artwork" v-for="artwork in artist_artworks" :key="artwork.artwork_id"/>
            </div>
          </div>
        </div>
        <!-- <div class="title-separator"></div>
        <div class="mt-10 px-5-md px-8-lg">
          <div class="_flex _between flex-wrap mb-8 mt-10 w-100">
            <div>
              <p class="headline bold m-0">Oeuvres similaires</p>
            </div>
            <div class="body-2 medium">
              <a href="" class="animated-underline"
                >Accéder à toutes les oeuvres similaires</a
              >
            </div>
          </div>
          <div class="card-columns mb-15">
            <div class="card rounded-0">
              <div class="artists_follow_card artwork-card mb-7">
                <div class="artists_follow_img_link">
                  <div
                    style="background-image:url(https://d7hftxdivxxvm.cloudfront.net?resize_to=fill&width=400&height=300&quality=80&src=https%3A%2F%2Fd32dm0rphc51dk.cloudfront.net%2FMs2WrkDAKW7zZHZ4QCp6Gw%2Flarge.jpg)"
                    class="artists_follow_img"
                  ></div>
                </div>
                <div class="mt-1">
                  <div class="d-flex flex-row _between">
                    <div>
                      <div class="bold text-uppercase m-0">Shintaro Miyake</div>
                      <div class="artist-cell-item__bio">
                        <div class="italic">Magdalena della Piazza, 2016</div>
                        <div style="font-style:normal!important;">
                          20 x 16 inch
                        </div>
                      </div>
                      <div class="_flex _between mt-2">
                        <div class="medium">Sculpture</div>
                      </div>
                    </div>
                    <div
                      class="subheading text-right align-self-end"
                      style="line-height:16px;"
                    >
                      <div class="bold text-danger">1280 $</div>
                      <del class="text-muted body-1">950 $</del>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card rounded-0">
              <div class="artists_follow_card artwork-card mb-7">
                <div class="artists_follow_img_link">
                  <div
                    style="background-image:url(https://d7hftxdivxxvm.cloudfront.net?resize_to=fill&width=400&height=300&quality=80&src=https%3A%2F%2Fd32dm0rphc51dk.cloudfront.net%2FMs2WrkDAKW7zZHZ4QCp6Gw%2Flarge.jpg)"
                    class="artists_follow_img"
                  ></div>
                </div>
                <div class="mt-1">
                  <div class="d-flex flex-row _between">
                    <div>
                      <div class="bold text-uppercase m-0">Shintaro Miyake</div>
                      <div class="artist-cell-item__bio">
                        <div class="italic">Magdalena della Piazza, 2016</div>
                        <div style="font-style:normal!important;">
                          20 x 16 inch
                        </div>
                      </div>
                      <div class="_flex _between mt-2">
                        <div class="medium">Sculpture</div>
                      </div>
                    </div>
                    <div
                      class="subheading text-right align-self-end"
                      style="line-height:16px;"
                    >
                      <div class="bold text-danger">1280 $</div>
                      <del class="text-muted body-1">950 $</del>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card rounded-0">
              <div class="artists_follow_card artwork-card mb-7">
                <div class="artists_follow_img_link">
                  <div
                    style="background-image:url(https://d7hftxdivxxvm.cloudfront.net?resize_to=fill&width=400&height=300&quality=80&src=https%3A%2F%2Fd32dm0rphc51dk.cloudfront.net%2FMs2WrkDAKW7zZHZ4QCp6Gw%2Flarge.jpg)"
                    class="artists_follow_img"
                  ></div>
                </div>
                <div class="mt-1">
                  <div class="d-flex flex-row _between">
                    <div>
                      <div class="bold text-uppercase m-0">Shintaro Miyake</div>
                      <div class="artist-cell-item__bio">
                        <div class="italic">Magdalena della Piazza, 2016</div>
                        <div style="font-style:normal!important;">
                          20 x 16 inch
                        </div>
                      </div>
                      <div class="_flex _between mt-2">
                        <div class="medium">Sculpture</div>
                      </div>
                    </div>
                    <div
                      class="subheading text-right align-self-end"
                      style="line-height:16px;"
                    >
                      <div class="bold text-danger">1280 $</div>
                      <del class="text-muted body-1">950 $</del>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>
    <section id="quick-order" v-if="currentArtwork">
      <div class="_flex _between">
        <div class="_flex">
          <div class="thumb mr-3">
            <img v-if="currentArtwork" :src="currentArtwork.images" alt="" class="img-responsive"/>
          </div>
          <div>
            <div class="artist-name">{{ currentArtwork.name }}</div>
            <div class="artwork-title">{{ artist_full_name }}</div>
          </div>
        </div>
        <div class="_flex">
          <div class="price mr-5">
            {{ currentArtwork.price }} {{ currentArtwork.currency_id }}
          </div>
          <button class="btn black" @click="addToCart">
            Ajouter au panier
          </button>
        </div>
      </div>
    </section>
    <!-- Footer -->
    <main-footer />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Slideshow from "../components/utils/Slideshow.vue";

export default {
  components: { Slideshow },
  data: () => ({
    currentArtwork: null,
    artist_artworks: null,
    loading: true,
    images: [],
    qty: 1,
    addToCartLoading: false,
  }),
  computed: {
    ...mapGetters([
      "getToken",
      "getEmptyUserPicture",
      "getEmptyArtworkPicture",
    ]),
    artist_full_name(){
      if(this.currentArtwork && this.currentArtwork.data_user){
        return `${ this.currentArtwork.data_user.last_name } ${ this.currentArtwork.data_user.middle_name } ${ this.currentArtwork.data_user.first_name }`
      }
    }
  },
  watch: {
    qty(value) {
      if (value <= 1) {
        this.qty = 1;
      }
      if (value > this.currentArtwork.number_available_copies) {
        this.qty = this.currentArtwork.number_available_copies;
      }
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetOneData",
        "axiosPostData",
    ]),
    getMainPictureArtwork(arr_pictures){
      let mainPicture = arr_pictures.filter(picture => parseInt(picture.artwork_picture_id) === 0)[0]
      return mainPicture.src
    },
    addToCart() {
      if (!this.getToken) {
        return $("#notConnectedModal").modal("show");
      }else {
        this.addToCartLoading = true;
        this.axiosPostData({
          user_id: this.getToken,
          artwork_id: this.currentArtwork.artwork_id,
          quantity: this.qty,
          table: "addCart"
        })
          .then( response => {
            if(response.status === 200){
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              })
            }
            this.addToCartLoading = false;
          })
          .catch()
      }
    },
    goToCart() {
      return this.$router.push("/panier");
    },
    initWindow() {
      window.onscroll = function() {
        scrollFunction();
      };

      var offsetHeight = document.getElementById("scrollingDivView")
        .offsetHeight;
      var offsetArtworkHeight = document.getElementById("artworkView")
        .offsetHeight;
      var offsetHomeHeight = document.getElementById("home-container")
        .offsetHeight;
      var viewRightImage = document.getElementById("viewRightImage");
      var viewRightContent = document.getElementById("viewRightContent");

      // Get the navbar
      var navbar = document.getElementById("navbar");
      // Get the navbar
      var quickOrder = document.getElementById("quick-order");

      //Get the button:
      var mybutton = document.getElementById("myBtn");

      // Get the offset position of the navbar
      var sticky = navbar.offsetTop;

      // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position

      // When the user scrolls down 20px from the top of the document, show the button

      function scrollFunction() {
        if (window.pageYOffset > offsetHomeHeight) {
          quickOrder.style.display = "block";
          quickOrder.style.top = "0";
        } else {
          quickOrder.style.display = "none";
          quickOrder.style.top = "-70px";
        }
        if (window.pageYOffset > offsetHeight + 50) {
          viewRightImage.style.display = "block";
          viewRightImage.style.position = "fixed";
          viewRightContent.style.display = "none";
        } else {
          viewRightImage.style.display = "none";
          viewRightContent.style.display = "block";
        }
        if (window.pageYOffset > offsetArtworkHeight - 350) {
          viewRightImage.style.display = "none";
        }
        if (window.pageYOffset >= sticky) {
          navbar.classList.add("sticky");
        } else {
          navbar.classList.remove("sticky");
        }

        if (
          document.body.scrollTop > 400 ||
          document.documentElement.scrollTop > 400
        ) {
          mybutton.style.display = "block";
        } else {
          mybutton.style.display = "none";
        }
      }

      if (window.matchMedia("(max-width:991.98px)")) {
        viewRightImage.style.display = "none";
      }
    },
  },
  mounted() {},
  created() {
    this.axiosGetOneData({ table: "retrieveArtwork", table_id: this.$route.params.id })
        .then( response => {
          if(response.status === 200){
            this.currentArtwork = response.data.data
            if(this.currentArtwork.data_pictures){
              this.currentArtwork.data_pictures.filter(picture => this.images.push(picture.src));
            }
            /*this.$toast.success(response.data.message, {
              icon: "fas fa-2x fa-check-circle",
            })*/
            this.axiosGetOneData({ table: "retrieveArtworksByArtist", table_id: this.currentArtwork.user_id })
                .then( response => {
                  if(response.status === 200){
                    this.artist_artworks = response.data.data
                    /*this.$toast.success(response.data.message, {
                      icon: "fas fa-2x fa-check-circle",
                    })*/
                  }else {
                    /*this.$toast.error(response.data.message, {
                      icon: "fas fa-2x fa-times-circle",
                    })*/
                  }
                  this.loading = false;
                })
                .catch()
          }else {
            /*this.$toast.error(response.data.message, {
              icon: "fas fa-2x fa-times-circle",
            })*/
          }
          this.loading = false;
        })
        .catch()
  },
  beforeDestroy() {},
};
</script>

<style></style>
