<template>
  <!-- Container for the image gallery -->
  <div class="slide-container">
    <!-- Full-width images with number text -->
    <div class="mySlides" v-for="(image, i) in images" :key="i + 1">
      <div class="numbertext">{{ i + 1 }} / {{ images.length }}</div>
      <img :src="image" style="width:100%" />
    </div>

    <!-- Next and previous buttons -->
    <a class="prev" @click="plusSlides(-1)">&#10094;</a>
    <a class="next" @click="plusSlides(1)">&#10095;</a>

    <br />
    <!-- Thumbnail images -->
    <div class="slide-row">
      <div class="column" v-for="(image, i) in images" :key="i + 1">
        <img
          class="demo cursor"
          :src="image"
          style="width:100%"
          @click="currentSlide(i + 1)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    slideIndex: 1,
  }),
  methods: {
    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((this.slideIndex = n));
    },
    showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("demo");
      // var captionText = document.getElementById("caption");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
      // captionText.innerHTML = dots[this.slideIndex - 1].alt;
    },
  },
  updated() {
    if (this.images.length > 0) {
      setTimeout(() => {
        this.showSlides(this.slideIndex);
      }, 1000);
    }
  },
};
</script>

<style scoped>
/* Position the image container (needed to position the left and right arslide-rows) */
.slide-container {
  position: relative;
}

/* Hide the images by default */
.mySlides {
  display: none;
  height: 500px;
  background: rgba(0, 0, 0, 0.028);
}
.mySlides img {
  height: 100%;
  object-fit: cover;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Container for image text */
.caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.slide-row:after {
  content: "";
  display: table;
  clear: both;
}

/* Six columns side by side */
.column {
  float: left;
  width: 16.66%;
  height: 80px;
  margin-right: 10px;
}
.column img {
  height: 100%;
  object-fit: cover;
}

/* Add a transparency effect for thumnbail images */
.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .mySlides img {
    height: 280px;
  }
  .column {
    height: 50px;
  }
}
</style>
